import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

// Инициализация при загрузке DOM-дерева
document.addEventListener('DOMContentLoaded', () => {
  initFeedbacksSlider();

  initCountDownTimer();
});

function initFeedbacksSlider() {
  const sliderFeedbacks = new Swiper('.slider-feedbacks', {
    loop: true,
    watchSlidesProgress: true,
    spaceBetween: 0,
    slidesPerView: 1,
    speed: 400,
    autoplay: {
      delay: 5000,
    },
    /* effect: 'fade',
    fadeEffect: {
      crossFade: true,
    }, */
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    /* pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }, */
  });
}

function initCountDownTimer() {
  // Фиксированная дата и время окончания акции
  const endDate = new Date('2024-12-21T23:59:59').getTime();
  //const endDate = new Date('2024-12-21T16:18:00').getTime();

  function updateCountdown() {
    const now = Date.now();
    let distance = endDate - now;
    let price = 1999;
    let message = '';

    console.log(endDate);
    console.log(now);
    console.log(distance);

    // Расчет времени
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const totalHours = Math.floor(distance / (1000 * 60 * 60));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );

    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Обновление таймера
    document.getElementById('countdown-days').innerHTML =
      `${pad(days)}<span class="fs-4">дн</span>`;
    //document.getElementById('countdown-hours').innerHTML = `${pad(hours)}`;
    document.getElementById('countdown-hours').innerHTML = `${pad(totalHours)}`;
    document.getElementById('countdown-minutes').innerHTML = `${pad(minutes)}`;
    document.getElementById('countdown-seconds').innerHTML = `${pad(seconds)}`;

    document.querySelectorAll('[data-price]').forEach((el) => {
      el.innerHTML = price;
    });

    // Если время истекло, вывести сообщение
    if (distance <= 0) {
      document.getElementById('countdown').innerHTML = 'Время акции истекло!';
      message = '';

      changePricePayment(5400);

      document.querySelector('.box-counter__old-price').classList.add('d-none');
      document.querySelector('.sec-counter').classList.add('timeout');

      document.querySelectorAll('.acc-how-much-text').forEach((el) => {
        el.classList.toggle('d-none');
      });
    } else {
      // Обновление таймера через 1 секунду
      setTimeout(updateCountdown, 1000);
    }

    document.getElementById('message').innerHTML = message;
  }

  // Первоначальное обновление таймера
  updateCountdown();
}

function changePricePayment(newPrice) {
  document.querySelectorAll('[data-prodamusprice]').forEach((el) => {
    el.dataset.prodamusprice = newPrice;
  });

  document.querySelectorAll('[data-price]').forEach((el) => {
    el.innerHTML = newPrice;
  });
}

function pad(number) {
  return number < 10 ? '0' + number : number;
}
